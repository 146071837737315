<template>
    <v-row justify="start">
        <kurcc-form-multi-choice-additional-buttons-text-comment
            v-if="item.options.textComment"
            :answer="item.options.textCommentAnswer"
            :indexes="indexes"/>

        <kurcc-form-multi-choice-additional-buttons-photo-comment
            v-if="item.options.photoComment"
            :answer="item.options.photoCommentAnswer"
            :indexes="indexes"/>

        <kurcc-form-multi-choice-additional-buttons-report-issue
            v-if="item.options.reportIssue"
            :answer="item.options.reportIssueAnswer"
            :indexes="indexes"/>
    </v-row>
</template>

<script>
export default {
    name: "KurccFormMultiChoiceAdditionalButtons",
    props: {
        item: {
            type: Object,
            required: true
        },
        indexes: {
            type: Object,
            required: true
        }
    },
    components: {
        KurccFormMultiChoiceAdditionalButtonsTextComment:
            () => import('@/modules/forms/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsTextComment'),
        KurccFormMultiChoiceAdditionalButtonsPhotoComment:
            () => import('@/modules/forms/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsPhotoComment'),
        KurccFormMultiChoiceAdditionalButtonsReportIssue:
            () => import('@/modules/forms/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsReportIssue')
    }
}
</script>
